<template>
  <v-card flat outlined elevation="0" dense :color="color">
    <v-toolbar :color="color" dark elevation="0" dense>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="headline"><span class="text-values">{{ value }}</span></v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["title", "value", "color"],
};
</script>

<style scoped>
.text-values{
  color:#fff;
}
</style>
