<script>
import Calendar from "@/components/Calendars";
import Helpers from "@/mixins";
import Print from "./components/PrintDataItems";
import BoxValue from "./components/BoxValue.vue";
export default {
  mixins: [Helpers],
  components: {
    Calendar,
    Print,
    BoxValue,
  },
  data: () => ({
    isShow: false,
    total_confirmado: 0,
    total_recebido: 0,
    total_pendente: 0,
    items: [],
    dateEnv: {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      id_agencia: null,
    },
    agencias: [],
    dataSend: {
      id: null,
      valor_recebido: 0,
    },
    headers: [
      { text: "Agência", value: "AGENCIA", sortable: false },
      { text: "Forma de Envio", value: "forma_envio", sortable: false },
      {
        text: "Data/Hora Transferência",
        value: "data_hora_transferencia",
        sortable: false,
      },
      {
        text: "Valor Transferência",
        value: "valor_transferencia",
        sortable: false,
      },
      {
        text: "Data/Hora Confirmação",
        value: "data_hora_transferencia",
        sortable: false,
      },
      { text: "Confirmado", value: "confirmado", sortable: false },
      { text: "Valor Recebido", value: "valor_recebido", sortable: false },
      { text: "Valor em Falta", value: "valor_faltoso", sortable: false },
      { text: "Total", value: "valor_total", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
  }),
  mounted() {
    this.getAgencias();
    this.loadValues();
    this.Helpers;
  },
  methods: {
    printItem() {
      this.$htmlToPaper("printReport");
    },
    confirmReceipt() {
      this.$store.dispatch("activeLoader", true);
      this.dataSend.cashbox_id = this.$store.getters.getCurrentCashboxId;
      this.$http
        .post("/livrocaixa/conferencia-valores", this.dataSend)
        .then((result) => {
          this.$store.dispatch("showMessage", {
            show: true,
            color: "success darken-1",
            text: result.data.success,
          });
          this.loadValues();
          this.dataSend = {
            id: null,
            valor_recebido: 0,
          };
        });
    },
    loadValues() {
      this.total_confirmado = 0;
      this.total_pendente = 0;
      this.total_recebido = 0;
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post("/livrocaixa/transferencias", this.dateEnv)
        .then((result) => {
          this.total = 0;
          this.items = result.data;

          if (result.data.length > 0) {
            this.total_pendente = result.data.reduce((acc, current) => {
              if (current.confirmado == 0) {
                return (
                  parseFloat(current.valor_transferencia) + parseFloat(acc)
                );
              }
              return 0 + parseFloat(acc);
            }, 0);

            this.total_confirmado = result.data.reduce((acc, current) => {
              if (current.confirmado == 1) {
                return parseFloat(current.valor_total) + parseFloat(acc);
              }
              return 0 + parseFloat(acc);
            }, 0);

            this.total_recebido = result.data.reduce((acc, current) => {
              return parseFloat(current.valor_recebido) + parseFloat(acc);
            }, 0);
          }
          this.$store.dispatch("activeLoader", false);
        });
    },
    async getAgencias() {
      const response = await this.$http.get("/agencias");
      this.agencias = response.data;
    },
  },
};
</script>
