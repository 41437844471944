<template>
  <div style="width: 100%" id="printReport">
    <header
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
    >
      <img
        src="@/assets/logoBlue.png"
        alt="[logo]"
        style="width: 600px; padding: 20px"
      />
      <div>
        <h5>LIVRO CAIXA</h5>
        <p>De: {{ search ? convertDate(search.startDate) : search }} até {{search ? convertDate(search.endDate) : search}}</p>
      </div>
    </header>
    <br />
    <table style="text-align: center; width: 100%">
      <thead>
        <tr style="text-align: center; color: #363636">
          <th
            style="border-bottom: 1px solid rgb(214, 214, 214);padding:5px"
            v-for="(header, ih) in heads"
            :key="ih"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody v-for="(item, ind) in items" :key="ind">
        <tr style="border-bottom: 1px solid rgb(214, 214, 214)">
          <td style="padding:5px">
            {{ item.AGENCIA }}
          </td>
          <td style="padding:5px">
            {{ item.forma_envio }}
          </td>
          <td style="padding:5px">
            {{ convertDateTimeNew(item.data_hora_transferencia) }}
          </td>
          <td style="padding:5px">
            {{ convertCurrent(item.valor_transferencia) }}
          </td>
          <td style="padding:5px">
            {{ convertDateTimeNew(item.data_hora_confirmacao) }}
          </td>
          <td style="padding:5px">
            {{ item.confirmado ? "SIM" : "NÃO" }}
          </td>
          <td style="padding:5px">
            {{ convertCurrent(item.valor_recebido) }}
          </td>
          <td style="padding:5px">
            {{ convertCurrent(item.valor_faltoso) }}
          </td>
          <td style="padding:5px">
            {{ convertCurrent(item.valor_total) }}
          </td>
        </tr>
      </tbody>
    </table>
    <table
      style="
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
      "
    >
      <tbody>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td
            style="
              padding: 8px;
              text-align: center;
            "
          >
            <strong
              >TOTAL PENDENTE:
              {{ valor_pendente ? convertCurrent(valor_pendente) : "R$ 0,00" }}
            </strong>
            |
            <strong
              >TOTAL RECEBIDO:
              {{
                valor_recebido ? convertCurrent(valor_recebido) : "R$ 0,00"
              }}</strong
            >
            |
            <strong
              >TOTAL CONFIRMADO:
              {{
                valor_confirmado ? convertCurrent(valor_confirmado) : "R$ 0,00"
              }}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: [
    "items",
    "headers",
    "search",
    "valor_confirmado",
    "valor_recebido",
    "valor_pendente",
  ],
  mixins: [mixin],
  data: () => ({
    heads: [],
  }),
  mounted() {
    this.heads = [...this.headers];
    this.heads.splice(9, 1);
  },
};
</script>

